import "./App.css";
import HomePage from "./pages/HomePage";
import { Routes, Route } from "react-router-dom";
import NextTopLoader from "nextjs-toploader";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import HighlightsPage from "./pages/HighlightsPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import WhatsappFAB from "./components/WhatsappFAB/WhatsappFAB";
import SitePage from "./pages/SitePage";
import ScrollToTop from "./components/ScrollToTop";
import Fade from "react-reveal";
import ProjectsPage from "./pages/ProjectsPage";

function App() {
  return (
    <div className="App">
      <NextTopLoader color="red" />
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/highlights" element={<HighlightsPage />} />
        <Route path="/site" element={<SitePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
      <Fade>
        <Footer />
      </Fade>
      <WhatsappFAB />
      <ScrollToTop />
    </div>
  );
}

export default App;
