import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Testimonials.css";
import Fade from "react-reveal";
import TestimonialCard from "./TestimonialCard";
import { useState, useEffect } from "react";
import { dbRef } from "../../utils/firebase";
import { child, get } from "firebase/database";

export default function AssociatedDevelopers() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var snapshot = await get(child(dbRef, "testimonials"));
        if (snapshot.exists()) {
          setTestimonials(snapshot.val());
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="associated-developers testimonials">
      <Fade right>
        <h1>Testimonials</h1>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={8000}
          infinite={true}
        >
          {testimonials.map((element) => (
            <TestimonialCard key={element.author} element={element} />
          ))}
        </Carousel>
      </Fade>
    </div>
  );
}
