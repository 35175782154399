import React from "react";
import { useState, useEffect } from "react";
import { storage } from "../../utils/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import ProjectCard from "./ProjectCard";
import "./Projects.css";

function ProjectSection({ title }) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const projectsRef = ref(storage, `projects/${title}`);
      const listResult = await listAll(projectsRef);
      const items = listResult.items;

      const downloadURLs = await Promise.all(
        items.map(async (element) => {
          return await getDownloadURL(element);
        })
      );

      const regex = new RegExp(`projects%2F${title}%2F(.*?)\.jpg`);
      const obj = downloadURLs.map((element) => ({
        title: element.match(regex)[1].slice(3),
        image: element,
      }));

      setFiles(obj);
    };
    loadImages();
  }, []);
  return (
    <div>
      <h1>Projects at {title}</h1>
      <div className="projects-container">
        {files.map((element) => (
          <ProjectCard src={element.image} title={element.title} />
        ))}
      </div>
    </div>
  );
}

export default ProjectSection;
