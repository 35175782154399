import React from "react";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Fade } from "react-reveal";
import { storage } from "../utils/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

export default function HighlightsPage() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const highlightsRef = ref(storage, "highlights");
      const listResult = await listAll(highlightsRef);
      const items = listResult.items;

      const downloadURLs = await Promise.all(
        items.map(async (element) => {
          return await getDownloadURL(element);
        })
      );

      const files = downloadURLs.map((element) => ({
        original: element,
        thumbnail: element,
      }));

      setFiles(files);
    };
    loadImages();
  }, []);

  return (
    <>
      <Fade>
        <div>.</div>
        <h1 id="highlights-h1">Our Highlights</h1>
        <div className="gallery-container">
          <ImageGallery
            items={files.reverse()}
            thumbnailPosition="bottom"
            autoPlay={true}
          />
        </div>
      </Fade>
    </>
  );
}
