import React from "react";
import "./AssociatedDevelopers.css";

export default function AssociatedDeveloperCard({ src, partner }) {
  return (
    <div className="associated-developer-card">
      <img src={src} alt={partner} />
    </div>
  );
}
