import React from "react";
import ServiceCard from "./ServiceCard";
import "./Services.css";
import Slide from "react-reveal";

export default function Services() {
  const services = [
    {
      title: "Real Estate Consultancy",
      content:
        "Our realty company offers expert real estate consultancy services to guide you through your property-related decisions. Whether you're a first-time homebuyer or a seasoned investor, our knowledgeable team of professionals is here to help. We provide valuable insights, market analysis, and personalized advice to ensure you make informed decisions when buying or selling properties. With our consultancy, you'll navigate the real estate market with confidence.",
      image: "/images/consultancy.jpg",
    },
    {
      title: "Property Management",
      content:
        "Investing in real estate comes with the responsibility of property management. Our team of experienced property managers is dedicated to overseeing your real estate investments with care and efficiency. We handle all aspects of property management, including tenant screening, rent collection, maintenance, and regular inspections. Our goal is to maximize the value of your property while minimizing your stress, giving you peace of mind that your investment is in capable hands.",
      image: "/images/property management.jpg",
    },
    {
      title: "Home Loan Solutions",
      content:
        "Securing the right financing for your dream home is a crucial step in your real estate journey. Our company specializes in providing customized home loan solutions to match your financial needs and goals. We work with a network of trusted lenders to offer competitive interest rates and flexible terms. From pre-approval to closing, we'll assist you every step of the way, making the home loan process as seamless as possible.",
      image: "/images/home loan.jpg",
    },
  ];
  return (
    <div className="services">
      <Slide left>
        <ServiceCard
          title={services[0].title}
          content={services[0].content}
          src={services[0].image}
        />
      </Slide>

      <Slide right>
        <ServiceCard
          title={services[1].title}
          content={services[1].content}
          src={services[1].image}
        />
      </Slide>

      <Slide bottom>
        <ServiceCard
          title={services[2].title}
          content={services[2].content}
          src={services[2].image}
        />
      </Slide>
    </div>
  );
}
