import React from "react";
import { Outlet, Link } from "react-router-dom";
import TemporaryDrawer from "./TemporaryDrawer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./Header.css";

export default function Header() {
  return (
    <div className="header">
      <img className="logo" src="/images/K9 LOGOJPG.jpg" alt="logo" />

      <div className="nav">
        <Link to="/home">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/services">Services</Link>
        <Link to="/highlights">Highlights</Link>
      </div>

      <Link className="phone-button" to="tel:+917038320883">
        {/* <div className="phone-button"> */}
          <LocalPhoneIcon />
          <div className="phone-no">+91 7038320883</div>
        {/* </div> */}
      </Link>
      <TemporaryDrawer />
      <Outlet />
    </div>
  );
}
