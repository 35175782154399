import React from "react";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./WhatsappFAB.css";

export default function WhatsappFAB() {
  return (
    <div className="whatsapp-fab">
      <Fab
        aria-label="whatsapp"
        sx={{ backgroundColor: "#25D366", color: "#fff " }}
        href="https://wa.me/917038320883"
      >
        <WhatsAppIcon />
      </Fab>
    </div>
  );
}
