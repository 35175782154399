export const firebaseConfig = {
  apiKey: "AIzaSyBJgU-wwG6_lFVhP11tVr4cjkz5xl5aCM8",
  authDomain: "k9-realty-website.firebaseapp.com",
  databaseURL: "https://k9-realty-website-default-rtdb.firebaseio.com",
  projectId: "k9-realty-website",
  storageBucket: "k9-realty-website.appspot.com",
  messagingSenderId: "77226648781",
  appId: "1:77226648781:web:6b1542e0cf880c5e1ff584",
  measurementId: "G-MLNDT7XFH0",
};
