import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./Footer.css";

function Connect() {
  return (
    <div className="footer-subcontainer">
      <h2>Connect with us</h2>

      <div className="footer-link">
        <LocationOnIcon />
        <a
          href="https://maps.app.goo.gl/cSK4ES7VBQwBLZdf9"
          target="_blank"
          rel="noreferrer"
        >
          K9 Realty, 119, Crossroads, Bhumkar Chowk Service Rd, Shankar Kalat
          Nagar, Wakad Pune, Maharashtra 411057
        </a>
      </div>

      <div className="footer-link">
        <EmailIcon />
        <a href="mailto:shubhamkandle@k9realty.in">shubhamkandle@k9realty.in</a>
      </div>

      <div className="footer-link">
        <LocalPhoneIcon />
        <a href="tel:+917038320883">+91 7038320883</a>
      </div>

      <div className="footer-socials">
        <a
          href="https://www.linkedin.com/company/k9-realty/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon />
        </a>
        <a
          href="https://www.facebook.com/k9realty"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/k9realty/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
}

export default Connect;
