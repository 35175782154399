import React from "react";
import "./About.css";
import Fade from "react-reveal";

export default function DirectorsDesk() {
  return (
    <Fade left>
      <div className="about">
        <div className="about-container director">
          <h1>Director's desk</h1>
          <p>
            Welcome to K9 Realty, where we turn your real estate dreams into
            reality. As the Founder and Director of our esteemed realty company,
            I am honored to lead a team of dedicated professionals who are
            committed to helping you find your perfect property, whether it's
            your dream home, a lucrative investment, or a commercial space to
            grow your business. Our mission is to provide unparalleled
            expertise, exceptional service, and a seamless, stress-free
            experience for our clients. With years of industry experience and a
            passion for real estate, we're here to guide you through every step
            of your real estate journey, ensuring you make informed decisions
            that align with your goals. Thank you for considering K9 Realty as
            your real estate partner, and I look forward to helping you achieve
            your real estate aspirations.
          </p>
          <i>
            <p id="byline">
              - Shubham Kandle
              <br />
              Founder and Director
            </p>
          </i>
        </div>
        <img src="/images/director.jpeg" alt="WALL logo" />
      </div>
    </Fade>
  );
}
