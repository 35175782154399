import React from "react";
import "./About.css";
import Fade from "react-reveal";

export default function About() {
  return (
    <div className="about">
      <Fade right>
        <img src="/images/s.jpg" alt="WALL logo" />
        <div className="about-container">
          <h1>About K9 Realty</h1>
          <p>
            We, One of the most trusted property consultant across Pune & PCMC
            region Contact us to get your perfect home! With our sharp knowledge
            of the local market, We've helped over 50 families in the
            Pune/Pimpri Chinchwad area find their dream home — and We are
            confident we can help you find yours too.
          </p>
        </div>
      </Fade>
    </div>
  );
}
