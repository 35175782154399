import LocationCard from "./LocationCard";
import { useState, useEffect } from "react";
import "./Locations.css";
import { Fade, Zoom } from "react-reveal";
import { storage } from "../../utils/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

export default function Locations() {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const locationsRef = ref(storage, "locations");
      const listResult = await listAll(locationsRef);
      const items = listResult.items;

      const downloadURLs = await Promise.all(
        items.map(async (element) => {
          return await getDownloadURL(element);
        })
      );

      const locations = downloadURLs.map((element) => ({
        name: element.match(/locations%2F(.*?)\.jpg/)[1].slice(3),
        cardImage: element,
      }));

      setLocations(locations);
    };
    loadImages();
  }, []);

  return (
    <Fade>
      <div className="locations">
        <h1>Locations</h1>
        <div className="location-container">
          {locations.map((location) => (
            <Zoom key={location.name}>
              <LocationCard
                name={location.name}
                cardImage={location.cardImage}
              />
            </Zoom>
          ))}
        </div>
      </div>
    </Fade>
  );
}
