import React from "react";
import AssociatedDevelopers from "../components/AssociatedDevelopers/AssociatedDevelopers";
import Locations from "../components/Locations/Locations";
import ParallaxContainer from "../components/Legacy/ParallaxContainer";
import Services from "../components/Services/Services";
import Testimonials from "../components/Testimonials/Testimonials";
import { Fade, Zoom } from "react-reveal";

export default function HomePage() {
  return (
    <div className="page">
      <Fade>
        <video width="750" height="500" autoPlay muted loop poster="/videos/loading.gif" >
          <source src="/videos/home.mp4" type="video/mp4" />
        </video>
      </Fade>
      <Locations />
      <Zoom>
        <h1>Our Services</h1>
      </Zoom>
      <Services />
      <ParallaxContainer />
      <AssociatedDevelopers />
      <Testimonials />
    </div>
  );
}
