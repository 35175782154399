import React from "react";
import About from "../components/About/About";
import DirectorsDesk from "../components/About/DirectorsDesk";
import MissionVision from "../components/About/MissionVision";
import Fade from "react-reveal";

export default function AboutPage() {
  return (
    <>
      <Fade>
        <div className="banner">
          <img src="/images/WALL LOGO.jpg" alt="wall logo" />
        </div>
      </Fade>
      <About />
      <DirectorsDesk />
      <MissionVision />
    </>
  );
}
