import { Outlet, Link } from "react-router-dom";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import "./Footer.css";

export default function Direct() {
  return (
    <div className="footer-subcontainer direct">
      <h2>Direct links</h2>
      <div className="footer-link">
        <BookmarkAddedIcon />
        <Link to="/home">Home</Link>
      </div>

      <div className="footer-link">
        <BookmarkAddedIcon />
        <Link to="/about">About</Link>
      </div>

      <div className="footer-link">
        <BookmarkAddedIcon />
        <Link to="/services">Services</Link>
      </div>

      <div className="footer-link">
        <BookmarkAddedIcon />
        <Link to="/testimonials">Testimonials</Link>
      </div>

      <Outlet />
    </div>
  );
}
