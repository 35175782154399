import { Parallax } from "react-parallax";

import React from "react";
import Legacy from "./Legacy";

export default function ParallaxContainer() {
  return (
    <>
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={"images/legacy.jpg"}
        bgImageAlt="the dog"
        strength={-200}
      >
        <Legacy />
        <div style={{ height: "50px" }} />
      </Parallax>
    </>
  );
}
