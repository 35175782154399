import React from "react";
import ResponsiveIframeContainer from "./ResponsiveIframeContainer";
import Connect from "./Connect";
import Direct from "./Direct";
import "./Footer.css";
import { Fade, Zoom } from "react-reveal";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-contents">
        <Fade left>
          <Connect />
          <Direct />
        </Fade>
        <ResponsiveIframeContainer />
      </div>
      <Zoom>
        <div className="copyright">
          <p>©{new Date().getFullYear()} K9 Realty. All rights reserved.</p>
          <p id="des">
            Designed and developed by{" "}
            <a id="linkedin"
              href="https://www.linkedin.com/in/chinmay-annadate/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chinmay Annadate
            </a>
          </p>
        </div>
      </Zoom>
    </div>
  );
}
