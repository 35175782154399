import React from "react";
import Services from "../components/Services/Services";
// import Fade from "react-reveal";

export default function ServicesPage() {
  return (
    <>
      {/* <Fade>
        <div className="banner">
          <img src="/images/services.jpg" alt="wall logo" />
        </div>
      </Fade> */}
      <div>.</div>
      <div className="services"></div>
        <Services />
      
    </>
  );
}
