import React from "react";
import "./Projects.css";

function ProjectCard({ src, title }) {
  return (
    <div className="project-card">
      <img src={src} alt={title} />
      <h2>{title}</h2>
    </div>
  );
}

export default ProjectCard;
