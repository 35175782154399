import React from "react";
import "./Locations.css";

export default function LocationCard({ name, cardImage }) {
  return (
      <div
        className="location-card"
        style={{
          backgroundImage: `url(${cardImage})`,
          backgroundSize: "cover",
        }}
      >
        <p id="title">{name}</p>
      </div>
  );
}
