import React from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export default function TestimonialCard({ element }) {
  return (
    <div className="testimonial-card">
      <FormatQuoteIcon id="quote" fontSize="large" sx={{ color: "red" }} />
      <p>{element.content}</p>
      <div className="by-line">
        <div className="stars">
          {new Array(element.stars).fill(null).map((x, i) => (
            <StarIcon key={i} sx={{ color: "gold" }} />
          ))}
          {new Array(5 - element.stars).fill(null).map((x, i) => (
            <StarBorderIcon key={i} sx={{ color: "gold" }} />
          ))}
        </div>
        <h3>{element.author}</h3>
      </div>
    </div>
  );
}
