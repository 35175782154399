import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { dbRef } from "../utils/firebase";
import { child, get } from "firebase/database";
import ImageGallery from "react-image-gallery";

export default function SitePage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const name = queryParameters.get("name");

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await get(child(dbRef, name));
        if (snapshot.exists()) {
          setGallery(snapshot.val());
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  });

  return (
    <div className="site">
      <div className="gallery-container">
        <ImageGallery items={gallery} thumbnailPosition="bottom" />
      </div>

      <Link to="/">
        <div className="enquire-button">Enquire</div>
      </Link>
    </div>
  );
}
