import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AssociatedDeveloperCard from "./AssociatedDeveloperCard";
import "./AssociatedDevelopers.css";
import Fade from "react-reveal";
import { storage } from "../../utils/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";

export default function AssociatedDevelopers() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const associatedDevelopersRef = ref(storage, "associated-developers");
      const listResult = await listAll(associatedDevelopersRef);
      const items = listResult.items;

      const downloadURLs = await Promise.all(
        items.map(async (element) => {
          return await getDownloadURL(element);
        })
      );

      setFiles(downloadURLs);
    };
    loadImages();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  return (
    <div className="associated-developers">
      <Fade left>
        <h1>Associated Developers</h1>
        <Carousel responsive={responsive} autoPlay={true} infinite={true}>
          {files.map((element) => (
            <AssociatedDeveloperCard src={element} />
          ))}
        </Carousel>
      </Fade>
    </div>
  );
}
