import React from "react";
import ProjectSection from "../components/Projects/ProjectSection";
import { useState, useEffect } from "react";
import { dbRef } from "../utils/firebase";
import { child, get } from "firebase/database";
import { Fade } from "react-reveal";

function ProjectsPage() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var snapshot = await get(child(dbRef, "projects"));
        if (snapshot.exists()) {
          setProjects(snapshot.val());
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div>.</div>
      <Fade>
        <h1 id="projects-h1">Our Projects</h1>
      </Fade>

      {projects.map((element) => (
        <Fade bottom>
          <ProjectSection key={element} title={element} />
        </Fade>
      ))}
    </>
  );
}

export default ProjectsPage;
