import React from "react";
import CountUp from "react-countup";
import "./Legacy.css";
import Zoom from "react-reveal";

export default function Legacy() {
  return (
    <div className="legacy">
      <Zoom>
        <h1>Our Legacy</h1>
        <p>
          <CountUp end={100} delay={2} />+ Happy Customers
        </p>
      </Zoom>
    </div>
  );
}
