import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // Function to smoothly scroll to the top of the page
  const scrollToTop = () => {
    const scrollStep = -window.scrollY / 20;
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  // Automatically scroll to top whenever pathname changes with animation
  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null; // You don't need to return anything in this component
};

export default ScrollToTop;
