import React from "react";
import "./Footer.css";

export default function ResponsiveIframeContainer() {
  return (
    <div className="iframe-container">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.301055964556!2d73.75031577494453!3d18.6055239665742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9641918cafb%3A0xe88544d17e9bc898!2sK9%20Realty!5e0!3m2!1sen!2sin!4v1698670338782!5m2!1sen!2sin"
        allowFullScreen
      ></iframe>
    </div>
  );
}
