import React from "react";
import "./About.css";
import Fade from "react-reveal";

export default function MissionVision() {
  return (
    <div className="about">
      <Fade bottom>
        <div className="about-container mission-vision">
          <h1>Mission</h1>
          <ul>
            <li>
              Empowering dreams through personalized real estate solutions
            </li>
            <li>Building communities, one home at a time.</li>
            <li>Leading with innovation in the real estate industry</li>
            <li>Sustainable homes for a better tomorrow.</li>
            <li>Nurturing talent, delivering excellence.</li>
          </ul>
        </div>

        <div id="vision" className="about-container mission-vision">
          <h1>Vision</h1>
          <p>
            Our vision is to be the leading realty company, setting new industry
            standards through unwavering commitment to our clients, innovative
            technology, community engagement, team empowerment, sustainability,
            global expansion, and the establishment of trusted advisor status,
            all while delivering exceptional service and making a positive
            impact in the communities we serve.
          </p>
        </div>
      </Fade>
    </div>
  );
}
