import React from "react";
import "./Services.css";

export default function ServiceCard({ title, content, src }) {
  return (
    <div className="service-card">
      <img src={src} alt="WALL logo" />
      <div className="service-card-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
}
